import React, { useContext } from "react";
import Card from "./Card";
import HandContext from "../store/hand-context";
import classes from "./Cards.module.scss";

export default function Cards() {
  const handCtx = useContext(HandContext);
  const hand = handCtx.hand ? handCtx.hand : Array(5).fill("");
  const held = handCtx.held;

  const handParsed = hand.map((card, index) => {
    return (
      <Card
        key={`card-${index}`}
        card={card}
        onHeldBtnClickHandler={handCtx.onHeldBtnClick}
        cardID={index}
        isHeld={held.indexOf(index) !== -1 ? true : false}
        isHeldBtnDisabled={handCtx.drawPhase === "deal" ? false : true}
      />
    );
  });

  return <div className={classes["cards-wrapper"]}>{handParsed}</div>;
}
