import React from "react";
import classes from "./WinTable.module.scss";
import HandContext from "../store/hand-context";

export default function WinTable() {
  const WIN_COMBO = {
    ROYAL_FLUSH: 250,
    STRAIGHT_FLUSH: 50,
    FOUR_OF_A_KIND: 25,
    FULL_HOUSE: 8,
    FLUSH: 6,
    STRAIGHT: 4,
    THREE_OF_A_KIND: 3,
    TWO_PAIR: 2,
    JACKS_OR_BETTER: 1,
  };

  const handCtx = React.useContext(HandContext);

  // console.log("Win Combo", handCtx.winCombo);

  return (
    <div className={classes["win-tables-wrap"]}>
      <table className={classes["win-table"]}>
        <thead></thead>
        <tbody>
          <tr
            className={
              handCtx.winCombo === "ROYAL_FLUSH"
                ? classes["win-combo-long"]
                : null
            }
          >
            <td>ROYAL FLUSH</td>
            <td>{WIN_COMBO.ROYAL_FLUSH * handCtx.currentBet}</td>
          </tr>
          <tr
            className={
              handCtx.winCombo === "STRAIGHT_FLUSH" ? "win-combo-long" : ""
            }
          >
            <td>STRAIGHT FLUSH</td>
            <td>{WIN_COMBO.STRAIGHT_FLUSH * handCtx.currentBet}</td>
          </tr>
          <tr
            className={
              handCtx.winCombo === "FOUR_OF_A_KIND"
                ? classes["win-combo-long"]
                : null
            }
          >
            <td>FOUR OF A KIND</td>
            <td>{WIN_COMBO.FOUR_OF_A_KIND * handCtx.currentBet}</td>
          </tr>
        </tbody>
      </table>
      {/* </Box> */}

      {/* <Box p={0}> */}
      <table className={classes["win-table"]}>
        <thead></thead>
        <tbody>
          <tr
            className={
              handCtx.winCombo === "FULL_HOUSE"
                ? classes["win-combo-mid"]
                : null
            }
          >
            <td>FULL HOUSE</td>
            <td>{WIN_COMBO.FULL_HOUSE * handCtx.currentBet}</td>
          </tr>
          <tr
            className={
              handCtx.winCombo === "FLUSH" ? classes["win-combo-mid"] : null
            }
          >
            <td>FLUSH</td>
            <td>{WIN_COMBO.FLUSH * handCtx.currentBet}</td>
          </tr>
          <tr
            className={
              handCtx.winCombo === "STRAIGHT" ? classes["win-combo-mid"] : null
            }
          >
            <td>STRAIGHT</td>
            <td>{WIN_COMBO.STRAIGHT * handCtx.currentBet}</td>
          </tr>
        </tbody>
      </table>
      {/* </Box> */}

      {/* <Box p={0}> */}
      <table className={classes["win-table"]}>
        <thead></thead>
        <tbody>
          <tr
            className={
              handCtx.winCombo === "THREE_OF_A_KIND"
                ? classes["win-combo"]
                : null
            }
          >
            <td>THREE OF A KIND</td>
            <td>{WIN_COMBO.THREE_OF_A_KIND * handCtx.currentBet}</td>
          </tr>
          <tr
            className={
              handCtx.winCombo === "TWO_PAIR" ? classes["win-combo"] : null
            }
          >
            <td>TWO PAIR</td>
            <td>{WIN_COMBO.TWO_PAIR * handCtx.currentBet}</td>
          </tr>
          <tr
            className={
              handCtx.winCombo === "JACKS_OR_BETTER"
                ? classes["win-combo"]
                : null
            }
          >
            <td>JACKS OR BETTER</td>
            <td>{WIN_COMBO.JACKS_OR_BETTER * handCtx.currentBet}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
